<template>
  <div>
    <v-row align="center" class="mt-6">
      <v-col cols="6">
        <v-card-title>Уведомления о платежах</v-card-title>
      </v-col>
      <v-col cols="6" class="text-right">
        <v-btn color="orange" dark @click="onClickRefresh">Обновить</v-btn>
      </v-col>
    </v-row>
    <v-data-table
      class="notification-table"
      :headers="notificationHeaders"
      :items="paymentNotifications"
      height="auto"
      fixed-header
      hide-default-footer
      :no-data-text="errorMessage || 'Нет данных для отображения'"
    >
    <template slot="item.receivedAt" slot-scope="{ item }">
      {{ item.receivedAt | formatDate }}
    </template>
    <template slot="item.amount" slot-scope="{ item }">
      {{ item.amount }} руб.
    </template>
</v-data-table>
  </div>
</template>

<script>
import debounce from "lodash.debounce";

export default {
  name: "PaymentNotification",
  data() {
    return {
      paymentNotifications: [],
      notificationHeaders: [
        { text: 'Дата получения платежа', value: 'receivedAt', sortable: false },
        { text: 'Сумма', value: 'amount', sortable: false },
        { text: 'Банк получателя', value: 'recipientBank', sortable: false },
        { text: 'Компания', value: 'recipientCompanyName', sortable: false },
        // TODO: Раскомментировать, когда SMS-центр начнёт возвращать эти данные.
        // { text: 'Банк отправителя', value: 'sendersBank', sortable: false },
        // { text: 'Отправитель', value: 'senederName', sortable: false }
      ],
      errorMessage: null
    };
  },
  filters: {
    formatDate(value) {
      if (!value) return '';
      const date = new Date(value);
      return date.toLocaleString('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
    }
  },
  async mounted() {
    await this.fetchIncomingSms();
  },
  methods: {
    async fetchIncomingSms() {
      try {
        const response = await this.$axios.get(this.$enums.Endpoints.PaymentNotification.GetPaymentNotifications);
        this.paymentNotifications = response.data;
        this.errorMessage = null;
      } catch (error) {
        console.error('Ошибка при загрузке уведомлений:', error);
        this.errorMessage = 'Ошибка получения уведомлений';
        this.paymentNotifications = [];
      }
    },
    onClickRefresh: debounce(function () {
      this.fetchIncomingSms();
    }, 1500),
  }
};
</script>

<style scoped>
.notification-table.v-data-table {
  border-radius: 4px !important;
  overflow: hidden;
  background-color: transparent !important;
}

::v-deep .v-data-table-header th {
  background-color: transparent !important;
  font-size: 14px !important;
}
</style>