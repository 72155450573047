<template>
  <v-card flat class="px-5 pb-5">
    <v-card-title class="pb-8"
      >Получение автобусной маршрут квитанции</v-card-title
    >
    <v-row no-gutters justify="center" class="pr-15 pl-4">
      <v-col cols="12" align-self="center">
        <div v-if="!pdfFile" class="text-h5 mb-2">
          <v-icon class="mr-3 text-h4">mdi-information</v-icon> Загрузите
          PDF-документ
        </div>
        <file-input v-model="pdfFile" accept=".pdf" id="create-bus-receipt" />
      </v-col>
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            outlined
            label="Сбор"
            v-model="fee"
            :rules="[(val) => val > 0 || 'Введите число, например 10.50']"
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-btn
            @click="create"
            :loading="loading"
            color="orange"
            :disabled="isDisabled"
            style="width: 100%; height: 3.3rem"
          >
            Получить
          </v-btn>
        </v-col>
      </v-row>
    </v-row>

    <v-snackbar v-model="snackbar" color="warning" timeout="3000">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Закрыть
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import axios from "axios";
import FileInput from "@/components/UI/FileInput.vue";
import { downloadFile } from "@/mixins/utils.js";

export default {
  name: "CreateBusReceipt",
  components: { FileInput },
  data() {
    return {
      pdfFile: null,
      fee: "",
      loading: false,
      snackbar: false,
      snackbarText: "",
    };
  },
  methods: {
    async create() {
      if (!this.pdfFile || !this.pdfFile.length) {
        this.showWarning("Пожалуйста, загрузите PDF-документ");
        return;
      }
      if (!this.fee || this.fee <= 0) {
        this.showWarning("Пожалуйста, введите корректный сбор");
        return;
      }
      this.loading = true;
      try {
        const formData = new FormData();
        formData.append("pdfFile", this.pdfFile[0]);
        const formattedFee = this.fee.toString().replace(".", ",");
        formData.append("fee", formattedFee);

        const response = await axios.post(
          this.$enums.Endpoints.Receipt.CreateBusReceipt,
          formData,
          {
            responseType: "blob",
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const fileName = this.pdfFile[0]
          ? `new_${this.pdfFile[0].name}`
          : "new_bus_receipt.pdf";
        downloadFile(response.data, { name: fileName });
      } catch (e) {
        console.error("Ошибка при создании МК:", e);
        throw e;
        ест;
      } finally {
        this.loading = false;
      }
    },
    showWarning(text) {
      this.snackbarText = text;
      this.snackbar = true;
    },
  },
  computed: {
    isDisabled() {
      const feeNumber = Number(this.fee);
      return (
        !this.pdfFile ||
        !this.fee ||
        this.fee[0] === "-" ||
        isNaN(feeNumber) ||
        feeNumber <= 0
      );
    },
  },
};
</script>

<style scoped>
.v-card {
  background-color: transparent !important;
}
</style>
